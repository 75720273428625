.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-interface-container {
  width: 300px;
  max-width: 300px;
  transition: width 0.5s ease, max-width 0.5s ease;
  overflow: hidden;
}

.file-interface-container.collapsed {
  width: 60px;
  max-width: 60px;
}

.collapse-button {
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 1;
}

.flex-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.flex-container > * {
  flex-grow: 1;
  flex-basis: 0;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  height: 100vh; /* Full height */
  width: 100vw; /* Full width */  
  /* transform: scale(0.5); Adjust scale as needed */
  transform-origin: top left; /* Scale from the top left corner */
}

@media (max-width: 600px) {
  #root {
    /* Remove height and width settings */
    /* height: auto; */ /* Removed */
    /* width: auto; */ /* Removed */
    overflow: visible; /* Changed from auto to visible */
    body {
      overflow-x: auto; /* Allow horizontal scrolling */
      overflow-y: auto; /* Allow vertical scrolling */
    }

  }
}
